<template>
  <XtxDialog :title="title" :visible="visible" @closeDialog="cancel">
    <div v-if="loading" class="loading"></div>
    <!-- 註單明细表格 -->
    <div class="table-one f-flex" style="position: relative" v-if="table">
      <div class="g-col-1" style="height: 200px; overflow-y: scroll">
        <div class="m-th f-flex" style="position: absolute; z-index: 10000; width: 750px">
          <div :class="{ 'g-w15': true }">項目</div>
          <div :class="{ 'g-w50': true }">球號</div>
          <div :class="{ 'g-w20': true }">賠率</div>
          <div :class="{ 'g-w15': true }">金額</div>
        </div>
        <div style="height: 40px; width: 100%"></div>
        <div class="m-tr f-flex" v-for="(item, index) in bets.contents" :key="index">
          <div :class="{ 'g-w15': true }">{{ bets.betTypeName }}</div>
          <div :class="{ 'g-w50': true }">{{ connectStr(item) }}</div>
          <div :class="{ 'g-w20': true, 'f-f-red': true }">{{ item.odds }}</div>
          <div :class="{ 'g-w15': true }">{{ item.betMoney }}</div>
        </div>
        <div style="height: 40px; width: 100%"></div>
        <div class="m-th f-flex" style="position: absolute; width: 750px; bottom: 0">
          <div
            style="text-align: right; width: 100%; padding: 0 40px 0 0"
            :class="{ 'g-w100': true }"
          >共 {{ total.order }} 笔,合计：{{ total.amount }}</div>
        </div>
      </div>
    </div>
    <div class="table-one f-flex" v-else>
      <div class="g-col-1">
        <div :class="{ 'g-w100': true, 'm-th': true, 'f-flex': true }">
          單註 {{ singleAmount }} 組合 {{ total.order }} 總金額
          {{ total.amount }}
        </div>
        <div class="m-tr f-flex">
          <div :class="{ 'g-w15': true }" style="border-right: 1px #ccc solid">球號</div>
          <div :class="{ 'g-w85': true }">{{ bet.number }}</div>
        </div>
        <div class="m-tr f-flex">
          <div :class="{ 'g-w15': true }" style="border-right: 1px #ccc solid">賠率</div>
          <div :class="{ 'g-w85': true, 'f-f-red': true }">{{ bet.odds }}</div>
        </div>
      </div>
    </div>
    <!-- 按钮 -->
    <template #footer>
      <XtxButton @click="submit" type="primary" :style="{ marginRight: '20px' }">送出</XtxButton>
      <XtxButton @click="cancel" type="gray">取消</XtxButton>
    </template>
  </XtxDialog>
</template>

<script>
import { reactive, ref, nextTick, onMounted } from "vue";
import { addBetOrder } from "@/api/game";
import Message from "@/components/library/Message";
import sumBy from "lodash.sumby";
import map from "lodash.map";
import { useStore } from "vuex";
import { constituteResult, combination, cartesian } from "@/utils/tools";
import find from "lodash.find";
import Confirm from "@/components/library/Confirm";
export default {
  name: "LhcBetConfirm",
  props: {
    title: {
      type: String,
      default: "註單確認"
    },
    //投註结果 是否表格显示
    table: {
      type: Boolean,
      default: true
    },
    //單註金額
    singleAmount: {
      type: [Number, String],
      default: null
    },
    //排列組合數量
    combined: {
      type: Number,
      default: 0
    },
    //玩法 0 排列組合 1阶乘 2拖頭
    playing: {
      type: Number,
      default: 0
    },
    //投註限制
    filter: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  setup(props, { emit }) {
    //初始化页面
    onMounted(() => {
      nextTick(() => {
        keydownSubmitEvent();
      });
    });

    const store = useStore();
    let loading = ref(false);
    /**
     *是否显示弹窗
     */
    let visible = ref(false);
    /**
     *合计
     */
    let total = reactive({
      amount: 0,
      order: 0
    });
    /**
     * 下註
     */
    let bet = reactive({
      number: "",
      odds: ""
    });
    /**
     * 下註项
     */
    let bets = reactive({
      gameCode: 10001,
      plat: "0",
      betType: 1,
      betTypeName: "特碼A盤",
      tailCount: 0,
      contents: []
    });

    //打开弹窗钩子
    const open = data => {
      visible.value = true;
      emit("on-change-step", 1);
      nextTick(() => {
        for (const key in data) {
          bets[key] = data[key];
        }
        if (props.table) {
          total.order = bets.contents.length || 0;
          total.amount = sumBy(data.contents, "betMoney");
        } else {
          switch (props.playing) {
            case 0: //排列組合
              let nms = map(data.contents, "nums");
              const result = [];
              combination(nms, 0, props.combined, 0, [], result);
              // console.log(result);
              bet.number = nms.join(",");
              bet.odds = map(data.contents, "odds").join(",");
              total.order = result.length;
              bets.contents = [
                {
                  typeId: bets.betType,
                  tabType: 0,
                  betMoney: Number(props.singleAmount),
                  nums: bet.number
                }
              ];
              break;
            case 1: //阶乘
              bet.number = data.contents[0].nums;
              let left = data.contents[0].nums.split("#")[0].split(",");
              let right = data.contents[0].nums.split("#")[1].split(",");
              // console.log("left", left);
              // console.log("right", right);
              let jres = new Set();
              left.forEach(l => {
                right.forEach(r => {
                  let lr = [l, r].sort((a, b) => Number(a) - Number(b));
                  // console.log(l, "l ---- r", r, lr);
                  if (lr[0] != lr[1]) {
                    jres.add(lr.join(""));
                  }
                });
              });
              console.log("1 ---- jres", jres, jres.size);
              // const cresult = [...new Set(left.concat(right))];
              // const result000 = [];
              // combination(cresult, 0, 2, 0, [], result000);
              // console.log("result000", result000);
              total.order = jres.size;
              break;
            case 2: //拖頭玩法
              bet.number = data.contents[0].nums;
              total.order = bets.tailCount;
              break;
          }
          total.amount = total.order * props.singleAmount;
        }
      });
    };
    /**
     * 确定 提交
     */
    const submit = () => {
      if (total.amount <= 0) {
        Message({ type: "error", text: "下註金額不能为0" });
        return;
      }
      // if (props.filter && total.amount > props.filter.maxMoney) {
      //   Message({
      //     type: "error",
      //     text: "超过單註(" + props.filter.maxMoney + ")最高限制",
      //   });
      //   return;
      // }
      if (total.amount > store.state.player.profile.amount) {
        Message({ type: "error", text: "可用余額不足" });
        return;
      }
      bets.gameCode = store.state.app.currentGame.code;
      bets.plat = store.state.app.currentGame.plat;
      loading.value = true;
      delete bets.tailCount;
      delete bets.extBetContent;

      // console.log("confirm bets--->", bets);

      addBetOrder(bets)
        .then(data => {
          loading.value = false;
          if (data && data.code == 0) {
            visible.value = false;
            Message({ type: "success", text: "註單送出成功" });
            //修改剩余額度
            let profile = { ...store.state.player.profile };
            profile.amount -= total.amount;
            store.dispatch("player/playInfoAction", profile);
            //添加最新註單
            addBetsToLeftPanel();
            emit("on-confirm-success", bets.contents);
            emit("on-change-step", 0);
          } else {
            if (data.msg == "赔率已更新，请刷新后重新下注") {

              Confirm({ text: "赔率发生变化，点击确定为您刷新。" })
                .then(() => {
                    location.reload();
                })
                .catch(e => {
                   location.reload();
                });
              //  emit("on-confirm-flush");
            }else if(data.msg.indexOf('PRIMARY')>=0){
               Confirm({ text: "网络有卡顿，点击刷新查看是否成功~"})
                .then(() => {
                    location.reload();
                })
                .catch(e => {
                   location.reload();
                });
               emit("on-confirm-cancel");
            } 
             else {
              //  Confirm({ text: "註單送出失败" + "[" + data.msg + "]"})
              //   .then(() => {
              //       // location.reload();
              //   })
              //   .catch(e => {
              //      location.reload();
              //   });
                Message({ type: "error", text: "註單送出失败" + "[" + data.msg + "]" });
                emit("on-confirm-cancel");
            }
          }
        })
        .catch(() => {

           Confirm({ text: "註單送出失败,点击刷新重试一下~"})
                .then(() => {
                    location.reload();
                })
                .catch(e => {
                   location.reload();
                });
           emit("on-confirm-cancel");
          loading.value = false;
        });
    };
    /**
     * 取消
     */
    const cancel = () => {
      visible.value = false;
      emit("on-confirm-cancel");
      emit("on-change-step", 0);
    };
    // 添加至左侧面板
    const addBetsToLeftPanel = () => {
      store.dispatch("game/requestLastBetsAction", {
        pageNo: 1,
        pageSize: 10,
        dto: {
          termNumber: "1",
          betType: 9999,
          status: 0,
          gameCode: store.state.app.currentGame.code
        }
      });
      // if (bets.contents && bets.contents.length > 0) {
      //   let lastBets = [];
      //   bets.contents.forEach((element) => {
      //     lastBets.push({
      //       gmtCreate: new Date(),
      //       betContent: element.hasOwnProperty("tabTypeName")
      //         ? element.nums + "[" + element["tabTypeName"] + "]"
      //         : element.nums,
      //       betBaseMoney: element.betMoney,
      //     });
      //   });
      //   store.dispatch("game/lastBetsAction", {
      //     bets: lastBets,
      //     request: false,
      //   });
      // }
    };

    const keydownSubmitEvent = () => {
      document.onkeydown = e => {
        let _key = window.event.keyCode;
        if (_key === 13) {
          emit("on-key-down-submit");
        }
      };
    };

    const connectStr = item => {
      if (item.hasOwnProperty("tabTypeName")) {
        return item.nums + "[" + item["tabTypeName"] + "]";
      } else {
        return item.nums;
      }
    };

    return {
      store,
      loading,
      visible,
      total,
      bets,
      bet,
      open,
      submit,
      cancel,
      addBetsToLeftPanel,
      keydownSubmitEvent,
      connectStr
    };
  }
};
</script>

<style scoped lang="less">
.xtx-dialog {
  :deep(.wrapper) {
    width: 780px;
    .body {
      font-size: 14px;
      padding: 5px 5px;
    }
  }
}
</style>
